export class Platform {

  isMessagingSupported = false;

  constructor(isMessagingSupported = false) {
    this.isMessagingSupported = isMessagingSupported;
  }

  get getPlatform() {
    return navigator.platform;
  }

  static getPlatform() {
    const isIos = [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform);
    const isMacOS = navigator.platform === 'MacIntel';

    const isSupportedPlatform = !isIos && !isMacOS;
    const isSupportedMacOS = isMacOS;
    const isSupportedPWA = isIos && window.matchMedia('(display-mode: standalone)').matches;

    return new Platform(isSupportedPlatform || isSupportedMacOS || isSupportedPWA);
  }

}
