import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import Auth from 'services/Auth';

export default function PrivateRoute({
  redirectUrl = '/login',
  children,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        Auth.isAuthenticated() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectUrl,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
