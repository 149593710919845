import { LoggingLog } from './Log';
import { environment as env } from 'environments';

import { axiosInstance } from 'utils/API';

export const LoggingService = {
  sendLogs(eventKey) {
    const body = new LoggingLog(eventKey);
    if (env.loggingToken) {
      const headers = { 'L-Client': env.loggingToken };
      axiosInstance.post('logging', body, { headers }).catch(err => {
        console.log('sendLogs error', err);
      });
    }
  },
};
