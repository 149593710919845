import { logEvents } from './static';
import LocalStorageService from 'services/LocalStorageService';

export class LoggingLog {
  eventKey = '';
  eventDesc = '';
  clientKey = '';
  clientDesc = '';
  applicationKey = '';
  username = '';
  browser = '';
  operatingSystem = '';
  description = '';

  constructor(eventKey) {
    this.eventKey = eventKey;
    this.setParams(eventKey);
  }

  setParams(eventKey) {
    this.eventDesc = logEvents[eventKey].eventDesc;
    this.clientKey = logEvents[eventKey].clientKey;
    this.clientDesc = logEvents[eventKey].clientDesc;
    this.applicationKey = logEvents[eventKey].applicationKey;
    this.description = logEvents[eventKey].description;
    this.username = this.getUserName();
    this.browser = this.getBrowser();
    this.operatingSystem = this.getOperatingSystem();
  }

  getUserName() {
    const userData = LocalStorageService.getUserData();
    return userData.username || userData.user.login;
  }

  getBrowser() {
    const browsers = ['MSIE', 'Chrome', 'Firefox', 'Opera'];
    for (let i = 0; i < browsers.length; i++) {
      const browser = browsers[i];
      if (navigator.userAgent.search(browser) >= 0) return browser;
    }
    if (
      navigator.userAgent.search('Safari') >= 0 &&
      navigator.userAgent.search('Chrome') < 0
    ) {
      return 'Safari';
    }
    return null;
  }

  getOperatingSystem() {
    const masc = {
      Win: 'Windows OS',
      Mac: 'MacOS',
      X11: 'UNIX OS',
      Linux: 'Linux OS',
      Win32: 'Window mobile',
      Android: 'Android',
      iPhone: 'iPhone OS',
    };
    const platform = [
      'Android',
      'iPhone',
      'iPad',
      'iPod',
      'BlackBerry',
      'IEMobile',
      'Windows Phone',
      'Win',
      'Mac',
      'X11',
      'Linux',
    ];

    for (let i = 0; i < platform.length; i++) {
      if (navigator.userAgent.indexOf(platform[i]) > -1) {
        return masc[platform[i]] || platform[i];
      }
    }
    return null;
  }
}
