import { kebabCase } from 'utils/helpers.js';
import { environment as env } from 'environments';
const processEnv = process.env;
const { googleTagManagerId = null, googleAnalyticsId = null } = env;

export function appendElement(parentTag, options = {}) {
  var { attrs, attributes, inputs = [], target, position, children } = options;
  position = position || (target ? 'afterend' : 'afterbegin');
  attributes = attrs || attributes || {};

  const targetElement = target?.tagName
    ? target
    : document.querySelector(target || 'body');

  const existing = document.querySelector(parentTag);

  const [tagName, id] = parentTag.split('#');

  if (existing) return console.log('skip', parentTag);

  const inserted = document.createElement(tagName);

  if (id) {
    inserted.setAttribute('id', id);
  }

  for (const attribute in attributes) {
    inserted.setAttribute(kebabCase(attribute), attributes[attribute]);
  }

  for (const input in inputs) {
    inserted[input] = inputs[input];
  }

  targetElement.insertAdjacentElement(position, inserted);

  if (!Array.isArray(children)) return inserted;

  for (const child of children) {
    const [childTag, childAttrs] = Object.entries(child).flat();
    appendElement(
      childTag,
      Object.assign(childAttrs, {
        target: parentTag,
        position: 'afterbegin',
      })
    );
  }
}

export function googleAnalytics() {
  if (!googleAnalyticsId) return;
  const scriptContent = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', '${googleAnalyticsId}', 'auto');
    ga('send', 'pageview');`;

  const script = appendElement('script#ga-script', {
    attrs: {
      type: 'text/javascript',
    },
    target: 'head',
    position: 'afterbegin',
  });

  if (!script) return;
  script.textContent = scriptContent;
}

export function googleTagManager() {
  if (!googleTagManagerId || processEnv.NODE_ENV === 'development') return;

  const scriptContent = `window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag('js', new Date());

  gtag('config', '${googleTagManagerId}');`;

  const logic = appendElement('script#gtm-script-code', {
    attrs: {
      type: 'text/javascript',
    },
    target: 'head',
    position: 'afterbegin',
  });

  if (!logic) return;
  logic.textContent = scriptContent;

  appendElement('script#gtm', {
    attrs: {
      type: 'text/javascript',
      async: true,
      src: `https://www.googletagmanager.com/gtag/js?id=${googleTagManagerId}`,
    },
    target: 'head',
    position: 'afterbegin',
  });
}
