import React from 'react';
import ReactDOM from 'react-dom/client'; // Import from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles';
import * as serviceWorker from './serviceWorker';

import App from './App';

import arionTheme from './arionTheme';
import { Provider } from 'react-redux';
import getStore from 'core/configureStore';
import './locales/i18n';

const store = getStore();
const root = ReactDOM.createRoot(document.getElementById('root')); // Create a root
root.render(
  <ThemeProvider theme={arionTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);

serviceWorker.register();