export const USER_DATA = 'userData';
const WEB_PUSH_SUBSCRIPTION = 'webPushSubscription';
const VERSION_FILE_TOKEN = 'versionFileToken';
const TIMESTAMP_REGISTRATIONS_COLLECTION = 'timestamp-registrations-test';
const FLIGHTS_TIMESTAMPS = 'flightTimestamps';
const EXTRA_SERVICES = 'extraServices';

const LocalStorageService = {
  cachesExtraServices(data, sequenceNumber) {
    localStorage.setItem(
      `${EXTRA_SERVICES}-${sequenceNumber}`,
      JSON.stringify(data)
    );
  },
  fetchExtraServices(sequenceNumber, callback) {
    callback(
      JSON.parse(localStorage.getItem(`${EXTRA_SERVICES}-${sequenceNumber}`)) ||
        []
    );
  },
  getUserData() {
    return JSON.parse(localStorage.getItem(USER_DATA)) || {};
  },
  getWebPushSubscription() {
    return localStorage.getItem(WEB_PUSH_SUBSCRIPTION)
  },
  getVersionFileToken() {
    return localStorage.getItem(VERSION_FILE_TOKEN)
  },
  removeUserData() {
    localStorage.removeItem(USER_DATA);
  },
  setUserData(userData) {
    if (!userData.username && userData.user.login) {
      userData.username = userData.user.login;
    }
    if (!userData.user.defaultLanguage) {
      userData.user.defaultLanguage = { code: 'EN' };
    }

    localStorage.setItem(USER_DATA, JSON.stringify(userData));
  },
  setWebPushSubscription(token) {
    localStorage.setItem(WEB_PUSH_SUBSCRIPTION, token);
  },
  setVersionFileToken(token) {
    localStorage.setItem(VERSION_FILE_TOKEN, token);
  },
  cachesFlightsTimestamps(data) {
    const userData = this.getUserData();
    const { station } = userData;
    if (!station) return;
    const timestampData =
      JSON.parse(localStorage.getItem(FLIGHTS_TIMESTAMPS)) || {};

    const newTimestamps = Object.assign(timestampData, {
      [station]: data,
    });
    localStorage.setItem(FLIGHTS_TIMESTAMPS, JSON.stringify(newTimestamps));
  },
  getFlightsTimestamps() {
    const userData = this.getUserData();
    const { station } = userData;
    const data = JSON.parse(localStorage.getItem(FLIGHTS_TIMESTAMPS)) || {};
    return data && data[station] ? data[station] : [];
  },
  getTimestampRegistrationCollection() {
    return JSON.parse(localStorage.getItem(TIMESTAMP_REGISTRATIONS_COLLECTION));
  },
  setTimestampRegistrationCollection(option) {
    localStorage.setItem(
      TIMESTAMP_REGISTRATIONS_COLLECTION,
      JSON.stringify(option)
    );
  },
};

export default LocalStorageService;
