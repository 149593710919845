import axios from 'axios';
import { unregister } from 'serviceWorker';
import { environment as env } from 'environments';
import { deleteWebPushSubscription } from 'services/Firebase';
import LocalStorageService from 'services/LocalStorageService';

const USER_DATA = 'userData';
export const api = {
  baseURL: `${env.httpHost}`,
  flights: `${env.httpHost}/flights`,
  auth: `${env.httpHost}/auth/login`,
  extra: `${env.httpHost}/services/extra`,
  timestamps: `${env.httpHost}/timestamps/templates`,
};

export const axiosInstance = axios.create({
  baseURL: api.baseURL,
  responseType: 'json',
});

export const refreshPermissions = () => {
  return axiosInstance
    .get('permissions')
    .then(res => {
      const userData = LocalStorageService.getUserData();
      if (res && res.data) {
        const newPermisions = (res.data.permissions || '')
          .split(',')
          .map(i => `${userData.station}-${i}`)
          .join(',');
        userData.permissions = newPermisions;
        LocalStorageService.setUserData(userData);
      }
      return userData || {};
    })
    .catch(e => console.log(e));
};

axiosInstance.interceptors.request.use(
  function(config) {
    const jsonUserData = localStorage.getItem(USER_DATA);
    const { accessToken, station } =
      (jsonUserData && JSON.parse(jsonUserData)) || {};

    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
      'X-Station': station,
      Authorization: accessToken,
    };

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function(response) {
    const userData = JSON.parse(localStorage.getItem(USER_DATA)) || {};
    const token = userData.accessToken;
    const headersToken = response.headers && response.headers.authorization;

    if (headersToken && token && headersToken !== token.split(' ')[1]) {
      userData.accessToken = `Bearer ${headersToken}`;
      localStorage.setItem(USER_DATA, JSON.stringify(userData));
      refreshPermissions();
    }
    return response;
  },
  function(error) {
    const { response } = error;
    if (error.status === 401 || (response && response.status === 401)) {
      unregister();
      localStorage.removeItem(USER_DATA);
      deleteWebPushSubscription(LocalStorageService.getWebPushSubscription())
        .then(() => localStorage.clear())
        .catch(() => {
          const token = LocalStorageService.getWebPushSubscription();
          localStorage.clear();
          LocalStorageService.setWebPushSubscription(token);
        })
        .finally(() => {
          localStorage.setItem('signOutNotification', JSON.stringify(true));
          window.location.replace('/');
        });
    }
    return Promise.reject(error);
  }
);
