const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);

        navigator.serviceWorker.ready
          .then(() => {
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit https://bit.ly/CRA-PWA'
            );
          })
          .catch(err => {
            console.warn('register serviceWorker error', err);
          });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.addEventListener('updatefound', () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        console.log('registerValidSW');

        installingWorker.addEventListener('statechange', () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.

              console.log(
                'New content is available and will be used when all ' +
                  'tabs for this page are closed. See https://bit.ly/CRA-PWA.'
              );

              window.location.reload(true);

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log('Content is cached for offline use.');

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        });
      });
    })
    .catch(error => {
      console.log('Error during service worker registration:', error);
    });
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready
          .then(registration => {
            registration
              .unregister()
              .then(() => {
                window.location.reload();
              })
              .catch(error => {
                console.warm(' registration.unregister error', error);
              });
          })
          .catch(err => {
            console.warm('serviceWorker.ready err', err);
          });
      } else {
        console.log('Service worker found. Proceed as normal.');
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.warn(
        'No internet connection found. App is running in offline mode.'
      );
    });
}
export function unregisterSub() {
  navigator.serviceWorker
    .getRegistrations()
    .then(registrations => {
      registrations?.forEach(registration => {
        if (
          registration.scope.includes('firebase-cloud-messaging-push-scope')
        ) {
          registration.unregister();
          console.log('firebase-cloud-messaging-push-scope', ' unsubscribed');
        }
      });
    })
    .catch(err => {
      console.warn('serviceWorker.getRegistrations error', err);
    });
  if ('setAppBadge' in navigator && 'clearAppBadge' in navigator) {
    navigator.clearAppBadge();
  }
}

export function unregister() {
  unregisterSub();
  caches
    .keys()
    .then(keys => {
      return Promise.all(keys.map(key => caches.delete(key)));
    })
    .catch(err => {
      console.warn('unregister caches error', err);
    });
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(err => {
        console.warn('unregister serviceWorker error', err);
      });
  }
}
