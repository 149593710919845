import { SET_EXTRA_SERVICES, SET_USER } from 'actions';
import { createStore, combineReducers } from 'redux';

let store;
const UserDataState = JSON.parse(localStorage.getItem('userData')) || {};
const userDataReducer = (state = UserDataState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.data };
    default:
      return state || {};
  }
};

const extraServicesReducer = (state = [], action) => {
  switch (action.type) {
    case SET_EXTRA_SERVICES:
      return action.data || state;
    default:
      return state;
  }
};



const stationReducer = (state, action) => {
  return { ...state };
};

const rootReducers = {
  userDataState: userDataReducer,
  stationState: stationReducer,
  extraServicesState: extraServicesReducer,
};

const rootReducer = (state, action) => {
  return combineReducers(rootReducers)(state, action);
};

store = createStore(rootReducer);

const getStore = () => {
  if (!store) {
    throw new Error('redux store is not defined');
  }
  return store;
};

export default getStore;
