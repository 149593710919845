import { axiosInstance } from 'utils/API';

const StationsService = {
  getStations: () => {
    return axiosInstance.get('stations');
  },
  getProfileStation: (stations, stationId) => {
    return stations.find(station => station.id === stationId);
  },
  sortStationsByCountryBatches: stations => {
    let res = [];
    const stationsByCountry = {};
    for (let i = 0; i < stations.length; i++) {
      const station = stations[i];
      if (stationsByCountry[station.countryIsoAlpha2])
        stationsByCountry[station.countryIsoAlpha2].push(station);
      else stationsByCountry[station.countryIsoAlpha2] = [station];
    }
    const countriesKeys = Object.keys(stationsByCountry).sort();
    for (let i = 0; i < countriesKeys.length; i++) {
      const key = countriesKeys[i];
      if (stationsByCountry.hasOwnProperty(key)) {
        const countryBatch = stationsByCountry[key];
        res = res.concat(countryBatch);
      }
    }
    return res;
  }
};

export default StationsService;
