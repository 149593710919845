export const logEvents = {
  SUCCESSFUL_LOGIN: {
    eventKey: 'SUCCESSFUL_LOGIN',
    eventDesc: 'Successful login',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-identity',
    description: 'Successful authentication into the system',
  },
  ACCESS_ARION_CLIENT: {
    eventKey: 'OPEN_APPLICATION',
    eventDesc: 'Access Arion client',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion',
    description: 'Authenticated access to Arion client',
  },
  ACCESS_MESSAGE_BOX: {
    eventKey: 'ACCESS_MESSAGE_BOX',
    eventDesc: 'Access message box',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-messagebox',
    description: 'Access message box via Arion client',
  },
  ACCESS_FLIGHTS: {
    eventKey: 'ACCESS_FLIGHTS',
    eventDesc: 'Access flights',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-fis',
    description: 'Access flights application via Arion client',
  },
  ACCESS_INSTRUCTIONS: {
    eventKey: 'ACCESS_INSTRUCTIONS',
    eventDesc: 'Access instructions',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-instructions',
    description: 'Access instructions application via Arion client',
  },
  ACCESS_SERVICE_REGISTRATION: {
    eventKey: 'ACCESS_SERVICE_REGISTRATION',
    eventDesc: 'Access service registration',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-services',
    description: 'Access service registration application via Arion client',
  },
  ACCESS_WEATHER: {
    eventKey: 'ACCESS_WEATHER',
    eventDesc: 'Access weather',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-weather',
    description: 'Access weather application via Arion client',
  },
  ACCESS_TIMESTAMP_REGISTRATION: {
    eventKey: 'ACCESS_TIMESTAMP_REGISTRATION',
    eventDesc: 'Access timestamp registration',
    clientKey: 'ARION',
    clientDesc: 'Arion',
    applicationKey: 'arion-services-timestamp',
    description: 'Access timestamp registration application via Arion client',
  },
};
