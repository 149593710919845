import loggingToken from './loggingToken';
const env = process.env;
const IP = env.REACT_APP_IP;
const httpHost = `https://${IP}`;

export const environment = {
  topBarLabel: env.REACT_APP_ENV_LABEL || null,
  loggingToken: loggingToken || null,
  httpHost,
  googleAnalyticsId: env.REACT_APP_GOOGLE_ANALYTICS || null,
  googleTagManagerId: env.REACT_APP_GOOGLE_TAG_MANAGER || null,
  loadVerificationTallyFormId: env.REACT_APP_LOAD_VERIFICATION_TALLY_FORM_ID || null,
  INSPECTIONS: env.REACT_APP_INSPECTION_FORM_ID || null,
  BOARDING_INSPECTIONS: env.REACT_APP_BOARDING_INSPECTION_FORM_ID || null,
  CHECK_IN_INSPECTIONS: env.REACT_APP_CHECK_IN_INSPECTIONS_FORM_ID || null,
  ENVIRONMENTAL_AIRSIDE_INSPECTIONS: env.REACT_APP_ENVIRONMENTAL_AIRSIDE_INSPECTIONS_FORM_ID || null,
  ENVIRONMENTAL_OFFICE_INSPECTIONS: env.REACT_APP_ENVIRONMENTAL_OFFICE_INSPECTIONS_FORM_ID || null,
  DE_ANTI_ICING_INSPECTIONS: env.REACT_APP_DE_ANTI_ICING_INSPECTIONS_FORM_ID || null,
  tallyApiKey: env.REACT_APP_TALLY_API_KEY || null,
  firebase: {
    apiKey: env.REACT_APP_API_KEY,
    authDomain: env.REACT_APP_AUTH_DOMAIN,
    databaseURL: env.REACT_APP_DATABASE_URL,
    projectId: env.REACT_APP_PROJECT_ID,
    storageBucket: env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: env.REACT_APP_MESSAGING_SENDER_ID,
    appId: env.REACT_APP_APP_ID,
    publicVapidKey: env.REACT_APP_PUBLIC_VALID_KEY,
  },
};
