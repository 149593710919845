export const getUserData = data => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({ ...state });
  };
};
export const SET_USER = 'SET_USER';
export const setUser = data => ({
  type: SET_USER,
  data,
});

export const SET_EXTRA_SERVICES = 'SET_EXTRA_SERVICES';
export const setExtraServices = data => ({
  type: SET_EXTRA_SERVICES,
  data,
});


