import { axiosInstance } from 'utils/API';

const ProfilePageService = {
  getProfileData: () => {
    return axiosInstance.get('profile');
  },
  getProfileImage: () => {
    return axiosInstance.get('profile/photo');
  },

  getLanguages: () => {
    return axiosInstance.get('languages');
  },
  getFlagImageUrl: defaultLanguage => {
    const isCountry = defaultLanguage && defaultLanguage.countryIsoAlpha2;
    if (defaultLanguage && !!defaultLanguage.code)
      return defaultLanguage.code.toLowerCase();
    if (isCountry) return isCountry.toLowerCase();
    return '';
  },
  updateProfile: (value, type, profile) => {
    let body = profile;
    if (type === 'station') body.defaultStationId = value.id;
    if (type === 'language') body.defaultLanguage = value;
    if (type === 'extra') body.receiveExtraShiftNotifications = value;
    if (type === 'jobVacancy') body.receiveJobVacancyNotifications = value;
    return axiosInstance.post('profile', body);
  },
  fetchFilterGroupServices: () => {
    return axiosInstance.get('filter-groups');
  },
  submitFilterGroup: body => {
    return axiosInstance.post('profile/flight-filter-group', body);
  },
  fetchFilterGroup: () => {
    return axiosInstance.get('profile/flight-filter-group');
  },
  deleteFilterGroup: () => {
    return axiosInstance.delete('profile/flight-filter-group');
  },
};

export default ProfilePageService;
